define("discourse/plugins/discourse-6dc-chat-backup/discourse/helpers/utils", ["exports", "@ember/object", "I18n"], function (_exports, _object, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createButton = createButton;
  function createButton(mainText, onClick) {
    const formField = document.createElement("div");
    formField.classList.add("chat-form__row");
    const innerFormField = document.createElement("div");
    innerFormField.classList.add("chat-form__row-content");
    formField.append(innerFormField);
    const formButton = document.createElement("button");
    formButton.classList.add("archive-btn", "chat-form__btn", "btn-flat", "btn", "btn-icon-text");
    formButton.style.display = "flex";
    formButton.style.alignItems = "center";
    formButton.style.gap = "4px";
    formButton.style.padding = "0px";
    formButton.type = "button";
    const fakerSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-history" style="transition: all .1s linear;"><path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8"/><path d="M3 3v5h5"/><path d="M12 7v5l4 2"/></svg>`;
    const formButtonSvg = document.createElement("div");
    formButtonSvg.style.color = "rgba(255,255,255,.5)";
    formButtonSvg.style.transition = "";
    formButtonSvg.innerHTML = fakerSVG;
    const formButtonSpan = document.createElement("span");
    formButtonSpan.classList.add("d-button-label");
    formButtonSpan.style.display = "flex";
    formButtonSpan.style.alignItems = "center";
    formButtonSpan.style.gap = "2px";
    formButtonSpan.textContent = mainText;
    const spanElementBage = document.createElement("span");
    spanElementBage.style.background = "var(--tertiary)";
    spanElementBage.style.color = "var(--secondary)";
    spanElementBage.style.fontSize = ".5rem";
    spanElementBage.style.fontWeight = "bold";
    spanElementBage.style.padding = "3px";
    spanElementBage.style.borderRadius = "1000000px";
    spanElementBage.style.marginRight = "3px";
    spanElementBage.textContent = "Custom";
    formButtonSpan.prepend(spanElementBage);
    formButton.onmouseover = () => {
      formButton.style.color = "#fff";
      formButtonSvg.style.color = "#fff";
    };
    formButton.onmouseleave = () => {
      formButton.style.color = "var(--primary)";
      formButtonSvg.style.color = "rgba(255,255,255,.5)";
    };
    formButton.appendChild(formButtonSvg);
    formButton.appendChild(formButtonSpan);

    // parentDiv.appendChild(formField);

    innerFormField.appendChild(formButton);
    formField.onclick = onClick;
    return formField;
  }
});