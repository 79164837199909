define("discourse/plugins/discourse-6dc-chat-backup/discourse/initializers/chat-backup", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-6dc-chat-backup/discourse/helpers/utils", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/show-modal", "discourse/plugins/discourse-6dc-chat-backup/discourse/components/modal/backup-modal"], function (_exports, _pluginApi, _utils, _ajax, _ajaxError, _showModal, _backupModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import ChatBackup from '../lib/chat-backup';
  /**
   * [Reason for this approach]
   * We do not want to directly modify the existing chat plugin as it could cause 
   * conflicts with Discourse's future updates/upgrades, if better methods are available
   * to achieve this, do reach out!
   * -------------------------------
   * Email: admini@6do.world
   * -------------------------------
  */
  var _default = _exports.default = {
    name: 'chat-backup',
    initialize(owner) {
      (0, _pluginApi.withPluginApi)('0.8', api => {
        api.onPageChange(async url => {
          // If the chat is not enabled, we don't need to do anything
          const siteSettings = api.container.lookup('service:site-settings');
          if (!!!siteSettings.chat_enabled && !!!siteSettings.discourse_6dc_chat_backup) return;

          // Channel Settings
          if (url.startsWith("/chat") && url.endsWith("/settings")) {
            if (!!!api.getCurrentUser().admin) return;

            // If a better way is available to extract Channel ID, please replace
            const channelId = url.split("/").filter(f => f)[3];
            const button = (0, _utils.createButton)("Auto-backup channel", async e => {
              const {
                chat_channel,
                job: backupJob
              } = await (0, _ajax.ajax)(`/discourse-6dc-chat-backup/get_backup?target_channel_id=${channelId}`, {
                type: "GET"
              }).catch(_ajaxError.popupAjaxError);
              if (backupJob) {
                if (backupJob.last_backup) {
                  const momentDate = moment(backupJob.last_backup);
                  backupJob.last_backup = `${momentDate.format('YYYY-MM-DD HH:mm:ss')} (${momentDate.fromNow()})`;
                } else {
                  backupJob.last_backup = 'None';
                }
                if (backupJob.last_backup !== 'None') {
                  const momentDate = moment(backupJob.last_backup).add(siteSettings.chat_channel_backup_period * 1, 'days');
                  backupJob.next_backup = `${momentDate.format('YYYY-MM-DD HH:mm:ss')} (${momentDate.fromNow()})`;
                  if (backupJob.retention_days > 0) {
                    const createdAt = moment(chat_channel.created_at);
                    const nextRetentionPeriod = createdAt.add(backupJob.retention_days, 'days');
                    backupJob.retention_period = `${nextRetentionPeriod.format('YYYY-MM-DD HH:mm:ss')} (${nextRetentionPeriod.fromNow()})`;
                  } else {
                    backupJob.retention_period = "Unknown";
                  }
                } else {
                  backupJob.next_backup = 'None';
                  backupJob.retention_period = "None";
                }
              }
              owner.lookup("service:modal").show(_backupModal.default, {
                model: {
                  channelId,
                  backupJob
                }
              });
            });
            const finalElement = document.querySelectorAll(".chat-form > .chat-form__section > .chat-form__section-content");
            finalElement[finalElement.length - 2].append(button);
          }
        });
      });
    }
  };
});